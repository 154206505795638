import React, { useContext } from 'react'
import Utils from '../../services/utils'
import { Context } from '../..'

const SearchProductCard = ({card}) => {
    const {app} = useContext(Context);

  return (
    <div className='search-card'>
        <img src={card.image} alt="" />
        <div className='title'>{card.title}</div>
        <div className='price'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</div>
    </div>
  )
}

export default SearchProductCard