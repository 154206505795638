import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import PageTop from '../../components/simple/PageTop';
import SamplePageContainer from '../../components/complex/SamplePageContainer';

const SamplePage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/page/${slug}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <>
        {loading ?
            <div className="isLoading2"></div>
        :
        items &&
            <div>
                <PageTop item={items}/>
                <SamplePageContainer item={items}/>
            </div>
        }
    </>
  )
})

export default SamplePage