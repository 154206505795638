import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import PageTop from '../../components/simple/PageTop';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import ProductDetailContainer from '../../components/complex/ProductDetailContainer';
import ProductTabs from '../../components/simple/ProductTabs';
import RelatedProducts from '../../components/complex/RelatedProducts';
import { useState } from 'react';
import RecentViewProducts from '../../components/complex/RecentViewProducts';

const ProductDetail = observer(() => {
    const {slug} = useParams();
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const{lang: lang2} = useParams();
    const [ids, setIds] = useState();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/products/${slug}`);
            }
        }
    }, [app.lang])

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug, null, app.lang);

    useEffect(() => {
        if(info){
            if(info.related_products){
                let str = ''
                info.related_products.forEach(element => {
                    str += `ids[]=${element}&` 
                });
                setIds(str);
            }
        }
    }, [info])

    useEffect(() => {
        if(info){
            let names = localStorage.getItem("recent_products") ? JSON.parse(localStorage.getItem("recent_products")) : [];
            if(names.length >= 12){
                names.splice(0, 1);
            }
            if(!names.find(it => it === info.id)){
                names.push(info.id);
            }
            localStorage.setItem('recent_products', JSON.stringify(names))
        }
    }, [info])

    const parent = {
        title: app.localizationsItems?.catalog,
        url: `/${app.lang}/catalog`
    }
    
  return (
    <>
        {
            loading ?
                <div className="isLoading2"></div>
            :
            info &&
                <div>
                    <PageTop item={info} parent={parent}/>
                    {info && <ProductDetailContainer item={info}/>}
                    {info && <ProductTabs item={info}/>}
                    {ids && <RelatedProducts ids={ids}/>}
                    <RecentViewProducts/>
                </div>
        }
    </>
  )
})

export default ProductDetail