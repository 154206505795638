import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils';
import { Context } from '../..';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';

const SummaryBoxCheckout = observer(({bonuseUse, setBonuseUse, delivery, balance}) => {
    const {app, user} = useContext(Context);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [bonuses, setBonuses] = useState();
    const [discount, setDiscount] = useState();
    const [deliveryPrice, setDeliveryPrice] = useState();

    useEffect(() => {
        if(delivery?.value?.length >= 1){
            for(let i = 0; i<delivery.value.length; i++) {
                if(total > delivery.value[i].price_from && (total < delivery.value[i].price_to || (delivery.value[i].price_to === 0 || !delivery.value[i].price_to))){
                    setDeliveryPrice(delivery.value[i].delivery_price)
                }
            }
        }else if(delivery){
            setDeliveryPrice(null)
        }
    }, [delivery])

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            let temp2 = 0;
            let temp3 = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                temp2 += Number(user.cartProducts[i]?.old_price ?  user.cartProducts[i]?.old_price : user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                if(user.cartProducts[i].bonus?.type == 1){
                    temp3 += (Math.floor((Number(user.cartProducts[i].price) * Number(user.cartProducts[i].bonus.number))/100) * Number(user.cartProducts[i].count))
                }else if(user.cartProducts[i].bonus){
                    temp3 += (Math.floor(Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count)))
                }
            }
            setTotal(temp);
            setSubTotal(temp2);
            if(temp3 && temp3 > 0){
                setBonuses(temp3);
            }
            if(temp !== temp2){
                setDiscount(Number(temp2)-Number(temp))
            }
        }
    }, [user.cartProducts])

    const handleUseBonuses = (e) => {
        setBonuseUse(!bonuseUse);
    }

  return (
    <div className="summary-box">
        <div>
            <div className="summery-header">
                <h3>{app.localizationsItems?.total_cart}</h3>
            </div>

            <ul className="summary-list">
                {user?.cartProducts?.map((product, index) =>
                    <li key={index}>
                        {product.bonus && 
                            <span className='cart-bonus'>{product.bonus.number}{product.bonus.type == 1 ? '%' : ''} 
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                    <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                                </svg>
                            </span>
                        }
                        <img src={product.image} alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
                        <h4>{product.title} <span>X {product.count}</span></h4>
                        <h4 className="price">
                            <div>{Utils.numberWithSpaces(Number(product.count) * Number(product.price))} {app.localizationsItems?.currency}</div>
                            {product.old_price && <div className='old-price'>{Utils.numberWithSpaces(Number(product.count) * Number(product.old_price))} {app.localizationsItems?.currency}</div>}
                        </h4>
                    </li>
                )}
            </ul>

            {(user.token && balance > 0) && 
                <div className='bonus-switcher'>
                    <div className='text'>
                        <h4>{app.localizationsItems?.spend_bonuses}</h4>
                        <span>{Utils.numberWithSpaces(balance)} {app.localizationsItems?.bonuses_accumulated}</span>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value={bonuseUse} onChange={handleUseBonuses}/>
                    </div>
                </div>
            }
            <ul className="summary-total">
                <li>
					<h4>{app.localizationsItems?.subtotal}</h4>
					<h4 className="price">{Utils.numberWithSpaces(subTotal)} {app.localizationsItems?.currency}</h4>
				</li>
                {deliveryPrice && <li>
                    <h4 >{app.localizationsItems?.delivery}</h4>
                    <h4 className="price">{Utils.numberWithSpaces(deliveryPrice)} {app.localizationsItems?.currency}</h4>
                </li>}
                {discount && <li className='discount-item'>
                    <h4 >{app.localizationsItems?.discount}</h4>
                    <h4 className="price">- {Utils.numberWithSpaces(discount)} {app.localizationsItems?.currency}</h4>
                </li>}
                {bonuseUse ? <li>
                    <h4>{app.localizationsItems?.bonuses_used}</h4>
                    <h4 className="price">- {Utils.numberWithSpaces(balance)} {app.localizationsItems?.currency}</h4>
                </li> : <></>}
                <li className="list-total">
					<h3>{app.localizationsItems?.total}</h3>
					<h3 className="price">
                        <div>{deliveryPrice ? Utils.numberWithSpaces(total + Number(deliveryPrice)) : Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</div>
                    </h3>
				</li>
                {bonuses ? <li>
                    <h4>{app.localizationsItems?.will_credited}</h4>
                    <h4 className="price">
                        <span className='price-bonus'>+ {bonuses} 
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                            </svg>
                        </span>
                    </h4>
                </li> : <></>}
            </ul>
        </div>
        <button className="btn btn-salmon tra-salmon-hover mt-30" disabled={Number(app.minimumOrderAmount) > total}>{app.localizationsItems?.checkout}</button>
        {Number(app.minimumOrderAmount) > total && <div className='red mt-6'>{app.localizationsItems?.minimal_sum_text} {Utils.numberWithSpaces(app.minimumOrderAmount)} {app.localizationsItems?.currency}</div>}
    </div>
  )
})

export default SummaryBoxCheckout