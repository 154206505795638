import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..'
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthServices from '../../services/general/authServices';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import PhoneCheckModal from '../modals/PhoneCheckModal';

const LoginContainer = observer(() => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();

    const [loading, setLoading] = useState();
    const [phoneCheckModal, setPhoneCheckModal] = useState();
    const [phone, setPhone] = useState();

    const {handleSubmit, register, control, formState: {errors}} = useForm();
	
	const CustomSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.logIn(data);

        if (res.statusCode === 200) {
            if(app.registerByPhone){
                setPhoneCheckModal(true);
                setPhone(data.phone);
            }else{
                user.setUser(res.content);
                user.setToken(res.content.token);
                user.setBasketChange(Math.random().toString(16));
                toast.success(res.message);
                navigate(`/${app.lang}`);
            }
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
    <div id="booking-2" className="wide-70 booking-section division">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div className="form-holder">
                        <div className="text-center">
                            <h3>{app.localizationsItems?.login_title}</h3>
                            <p className='p-xl'>{app.localizationsItems?.login_content}</p>
                        </div>
                        <form className="row justify-content-end booking-form" onSubmit={handleSubmit(CustomSubmit)}>
                            {app.registerByPhone ?
                                <div className="col-12">
                                    <div>
                                        <Controller defaultValue="" name="phone" control={control}
                                            render={({field: {onChange, value}}) => (
                                                <ReactInputMask onChange={onChange} value={value}
                                                                    mask="+7(999)999-99-99" maskChar="_">
                                                                    {
                                                                        inputProps => (
                                                                            <input type="tel" $error={errors.phone} required className="form-control phone" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                        )
                                                                    }
                                                </ReactInputMask>
                                            )}
                                            rules={{pattern: {
                                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                            }}}
                                        />
                                    </div>
                                </div>
                            :
                                <>
                                    <div className="col-lg-12">
                                        <input {...register('email')} required type="email" className="form-control email" placeholder={app.localizationsItems?.email}/> 
                                    </div>
                                    <div className="col-lg-12">
                                        <input {...register('password')} required type="password" className="form-control phone" placeholder={app.localizationsItems?.password}/> 
                                    </div>
                                </>
                            }
                            {!app.registerByPhone && 
                                <div className="col-12">
									<div className="forgot-box">
										<NavLink to={`/${app.lang}/forgot-password`} className="forgot-password">{app.localizationsItems?.forgot_password}</NavLink>
									</div>
								</div>
                            }          
                            <div className="col-auto mt-10">  
                                <button type="submit" className="btn btn-md btn-red tra-red-hover submit">{app.localizationsItems?.login}</button> 
                            </div>                              
                        </form>
                        {app.registerByPhone ?
                            <></>
                            :
                            <div className="row justify-content-center b-top pt-20 mt-20">
                                <div className="col-auto">
                                    <div className="sign-up-box text-center">
                                        <h4>{app.localizationsItems?.not_account}</h4>
                                        <NavLink to={`/${app.lang}/registration`}>{app.localizationsItems?.regis}</NavLink>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>	
                </div>	
            </div>
        </div>
        {phoneCheckModal && <PhoneCheckModal show={phoneCheckModal} setShow={setPhoneCheckModal} phone={phone} />}
    </div>
  )
})

export default LoginContainer