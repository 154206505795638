import React, { useContext, useEffect, useState } from 'react'
import DeliveryAddress from '../simple/DeliveryAddress'
import DeliveryOption from '../simple/DeliveryOption'
import PaymentMethod from '../simple/PaymentMethod'
import SummaryBoxCheckout from '../simple/SummaryBoxCheckout'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import UserServices from '../../services/general/userServices'
import CheckoutAddress from '../simple/CheckoutAddress'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import ReactInputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import AuthServices from '../../services/general/authServices'
import OrderPhoneCheckModal from '../modals/OrderPhoneCheckModal'
import AddAddressMapModal from '../modals/AddAddressMapModal'

const CheckoutContainer = observer(() => {
    const navigate = useNavigate();
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();
    const [bonuseUse, setBonuseUse] = useState(false);
    const [delivery, setDelivery] = useState();
    const [phoneCheckModal, setPhoneCheckModal] = useState();
    const [phone, setPhone] = useState();
    const [balance, setBalance] = useState(0);

    const [orderData, setOrderData] = useState();

    const [deliveryAddress, setDeliveryAddress] = useState();
    const [deliveryAddressError, setDeliveryAddressError] = useState();

    const methods = useForm();

    const userProfile = async () => {
        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            setBalance(res.content.balance);
        }
    }

    useEffect(() => {
        if(user.token){
            userProfile();
        }
    }, [])

    const CustomSubmit = async (data) => {
        if(!deliveryAddress) {
            setDeliveryAddressError(true);
            return
        }
        setLoading(true);
        const {address_id, city, street, house, apartment, entrance, floor, note, ...rest} = data;
 
        let newData ={}

        if(user.token){
            newData= {
                ...rest,
                address_id,
                uid: user.uid,
                bonus_used: bonuseUse && balance
            }
        }else{
            newData= {
                ...rest,
                ...deliveryAddress,
                uid: user.uid,
                bonus_used: bonuseUse && balance
            }
        }

        if(app.registerByPhone && !user.token){
            const res = await AuthServices.logIn({phone: data.phone});

            if(res.statusCode === 200){
                setOrderData(newData);
                setPhone(data.phone);
                setPhoneCheckModal(true);
            }else{
                toast.error(res.message);
            }
        }else{
            const res = await UserServices.cart.order.order(newData);

            if(res.statusCode === 200){
                user.removeUid();
                if(res.content.user?.token){
                    user.setToken(res.content.user.token);
                }
                user.setBasketChange(Math.random().toString(16));
                if(res.content.checkout_url){
                    window.open(res.content.checkout_url, '_self');
                }else{
                    navigate(`/${app.lang}`)
                }
                toast.success(res.message)
            }else if(res.statusCode === 502){
                user.removeUid();
                user.setBasketChange(Math.random().toString(16));
                navigate(`/${app.lang}/order-error`)
            }
            else{
                toast.error(res.message);
            }
        }
        
        setLoading(false);
    }

    const successCallback = async () => {
        setLoading(true);
        const res = await UserServices.cart.order.order(orderData);

        if(res.statusCode === 200){
            user.removeUid();
            if(res.content.user?.token){
                user.setToken(res.content.user.token);
            }
            user.setBasketChange(Math.random().toString(16));
            if(res.content.checkout_url){
                window.open(res.content.checkout_url, '_self');
            }else{
                navigate(`/${app.lang}`)
            }
        }else if(res.statusCode === 502){
            user.removeUid();
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}/order-error`)
        }
        else{
            toast.error(res.message)
        }
        setLoading(false);
    }


  return (
    <div className='container mt-50'>
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(CustomSubmit)}>
                <div className="row g-sm-4 g-3">
                    <div className="col-lg-8">
                        <div className="left-sidebar-checkout">
                            <div className="checkout-detail-box">
                                <ul>
                                    <li>
                                        <DeliveryOption delivery={delivery} setDelivery={setDelivery}/>
                                    </li>
                                    {delivery?.slug === "delivery" ?
                                        <>
                                            {user.token ?
                                                <li>
                                                    <DeliveryAddress/>
                                                </li>
                                                :
                                                <li>
                                                    <CheckoutAddress deliveryAddress={deliveryAddress} setDeliveryAddress={setDeliveryAddress}/>
                                                </li>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                    <li>
                                        <PaymentMethod/>
                                    </li>
                                    {!user.token && 
                                        <li>
                                            <div className="checkout-icon">
                                                <i className='fa-solid fa-user'/>
                                            </div>
                                            <div className='checkout-box'>
                                                <div className="checkout-title">
                                                    <h4>{app.localizationsItems?.user_data} <span className='red'>*</span></h4>
                                                </div>
                                                <div className="mb-3 custom-form">
                                                    <label>{app.localizationsItems?.name}</label>
                                                    <input type="text" className="form-control" required {...methods.register('name')} placeholder={app.localizationsItems?.name}/>
                                                </div>
                                                <div className="mb-3 custom-form">
                                                    <label>{app.localizationsItems?.email}</label>
                                                    <input type="email" className="form-control" required {...methods.register('email')} placeholder={app.localizationsItems?.email}/>
                                                </div>
                                                <div className="mb-3 custom-form">
                                                    <label>{app.localizationsItems?.phone}</label>
                                                    <Controller defaultValue="" name="phone" control={methods.control}
                                                            render={({field: {onChange, value}}) => (
                                                            <ReactInputMask onChange={onChange} value={value}
                                                                mask="+7(999)999-99-99" maskChar="_">
                                                                {
                                                                    inputProps => (
                                                                        <input type="tel" $error={methods.formState.errors.phone} className="form-control" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                    )
                                                                }
                                                            </ReactInputMask>
                                                            )}
                                                            rules={{pattern: {
                                                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                                            }}}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    }
                                    <li>
                                        <div className="checkout-icon">
                                            <i className='fa-solid fa-comments'/>
                                        </div>
                                        <div className='checkout-box'>
                                            <div className="checkout-title">
                                                <h4>{app.localizationsItems?.message}</h4>
                                            </div>
                                            <div className="mb-3 custom-form">
                                                <textarea type="text" className="form-control" {...methods.register('description')} placeholder={app.localizationsItems?.message}/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <SummaryBoxCheckout bonuseUse={bonuseUse} setBonuseUse={setBonuseUse} delivery={delivery} balance={balance}/>
                    </div>
                </div>
            </form>
        </FormProvider>
        {phoneCheckModal && <OrderPhoneCheckModal show={phoneCheckModal} setShow={setPhoneCheckModal} phone={phone} successCallback={successCallback}/>}
    </div>
  )
})

export default CheckoutContainer