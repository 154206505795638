import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import EditAddressModal from './EditAddressModal';
import { Context } from '../../..';
import UserServices from '../../../services/general/userServices';

const AddressCard = observer(({card, fetchItems}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const [modalOpen, setModalOpen] = useState();

    const deleteAddress = async () => {
        setLoading(true);
        const res = await UserServices.addresses.delete(card.id);

        if (res.statusCode === 200) {
            fetchItems();
        }
        setLoading(false);
    }   

  return (
    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div className={loading ? 'address-card isLoading' : 'address-card'}>
            <div className='address-card-top'>
                <span onClick={() => setModalOpen(true)}>
                    <i className='icon-pen'></i>
                </span>
                <span onClick={deleteAddress}>
                    <i className='icon-trash'></i>
                </span>
            </div>
            <div className='text'><span>{card?.full_address}</span></div>
            <div className='text'><span>{app.localizationsItems?.apartment}: </span>{card?.json_address?.apartment || '-'}</div>
            <div className='text'><span>{app.localizationsItems?.entrance}: </span>{card?.json_address?.entrance || '-'}</div>
            <div className='text'><span>{app.localizationsItems?.floor}:</span>{card?.json_address?.floor || '-'}</div>
            <div className='text'><span>{app.localizationsItems?.note}:</span>{card?.json_address?.note || '-'}</div>
        </div>
        {modalOpen && <EditAddressModal show={modalOpen} setShow={setModalOpen} id={card.id} fetchItems={fetchItems}/>}
    </div>
  )
})

export default AddressCard