import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import img from "../../images/placeholder.png"

const ProductBestCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="col-lg-6">
        <NavLink to={`/${app.lang}/products/${card.slug}`}>
            <div id="pb-5-1" className="pbox-5 bg-lightgrey">
                <div className="pbox-5-txt">
                    <h4 className="h4-xs coffee-color">{card.title}</h4>
                    {card.state && <span className="item-code" style={{backgroundColor: card.state.subtitle}}>{card.state.name}</span>}
                    <div className="pbox-5-img text-center mt-25 rel">	
                        <img className="img-fluid big-img" src={card.image ? card.image : img} alt={card.title} title={card.title}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            width={394}
                            height={394}
                        />
                        <div className="price-badge-sm bg-fixed">
                            <div className="badge-txt">
                                <h5 className="h5-sm">{app.localizationsItems?.only}</h5>
                                <h4 className="h4-md">{card.price} {app.localizationsItems?.currency}</h4>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </NavLink>
    </div>
  )
})

export default ProductBestCard