import axios from "axios";
import {apiAdminUrl, interceptorsConfig} from "../CONSTANTS";

const http = axios.create({baseURL: apiAdminUrl});

http.interceptors.request.use(interceptorsConfig);

http.CancelToken = axios.CancelToken;
http.isCancel = axios.isCancel;

let CancelToken = http.CancelToken;
let source = CancelToken.source();

export default class ImageUpload {
    static upload = (file, onUploadProgress) => {
        let formData = new FormData();

        formData.append('file', file);

        return this.postData("/upload/image", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            cancelToken: source.token,
            onUploadProgress
        })
    }

    static cancel = () => source.cancel()

    static postData = async (url, data = {}, headers = null) => {
        try {
            const {data: response} = await http.post(url, data, headers);
            return response;
        } catch (e) {
            if (http.isCancel(e)) {
                if (localStorage.getItem('i18nextLng') === 'ru') {
                    return {
                        message: "Отмена"
                    }
                } else {
                    return {
                        message: "Жою"
                    }
                }
            } else {
                const {data: response} = e.response;
                if (response.statusCode === 500 && response.message === "Unauthenticated.") {
                    localStorage.removeItem('token');
                }
                return response;
            }
        }
    }

    static uploadUrl = apiAdminUrl + "/upload/image";

    static headers = {
        "Authorization": localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null,
        "language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'ru'
    }
}
