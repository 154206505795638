import { observer } from 'mobx-react-lite';
import React from 'react'
import { useContext } from 'react';
import { Context } from '../..';
import { useState } from 'react';
import { useEffect } from 'react';
import CatalogServices from '../../services/general/catalogServices';
import BestOfferCard from '../simple/BestOfferCard';
import BestOffercard2 from '../simple/BestOffercard2';

const BestOffers = observer(({elem}) => {
    const {user} = useContext(Context);
    
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const fetchProducts = async () => {
        setLoading(true);
        let str = '';
        let str2 = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';

        elem.elements.forEach(element => {
            str += `ids[]=${element.id}&`
        });

        const res = await CatalogServices.products.index(`${str}${str2}`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }
        setLoading(false)
    }

    useEffect(() => {
        if(elem.elements){
            fetchProducts()
        }
    }, [elem.elements])

  return (
    <section id="promo-7" className="wide-100 promo-section division">
        <div className="container">
            <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                    <div className="section-title mb-60 text-center">	
                        <h2 className="h2-xl red-color">{elem.title}</h2>		
                        <div className="p-xl" dangerouslySetInnerHTML={{__html: elem.description}}></div>
                    </div>	
                </div>
            </div>
            <div className="row d-flex align-items-center">
                {items?.map((card, index) =>
                    index%2 === 0 ?
                        <BestOfferCard card={card} key={index}/>
                    :
                        <BestOffercard2 card={card} key={index}/>
                )}
            </div>
        </div>	
    </section>
  )
})

export default BestOffers