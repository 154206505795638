import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Context } from '../..';

const ProductTabs = observer(({item}) => {
    const [tabs, setTabs] = useState();

    const {app} = useContext(Context);

    useEffect(() => {
        if(item){
            setTabs(item.elements?.find(it => it.type === "tab-component")?.elements);
        }
    }, [item])

  return (
    <section id="product-1-data" className="wide-80 single-product-data division">
        <div className="container">
            <div className="row">
                {tabs ?
                    <div className="col-md-12">
                        <Tab.Container defaultActiveKey="tab-description0">
                            <div className="tabs-nav">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <Nav className="tabs-1 clearfix">
                                            {tabs?.map((card, index) =>
                                                <Nav.Item key={index} className="tab-link">
                                                    <Nav.Link eventKey={`tab-description${index}`}>
                                                        <h5 className="h5-sm">{card.title}</h5></Nav.Link>
                                                </Nav.Item>
                                            )}
                                        </Nav>
                                    </div>
                                </div>	
                            </div>
                            <Tab.Content className="tabs-content">
                                {tabs?.map((card, index) =>
                                    <Tab.Pane className="tab-pane" eventKey={`tab-description${index}`} key={index}>
                                        <div className="product-description" dangerouslySetInnerHTML={{ __html: card.description}}></div>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                :
                    <div className="col-md-12">
                        <div>
                            <div className="tabs-nav">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <ul className="tabs-1 clearfix">
                                            <li className="tab-link current" data-tab="tab-1">
                                                <h5 className="h5-sm">{app.localizationsItems?.description}</h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>	
                            </div>
                            <div className="tabs-content">
                                <div id="tab-1" className="tab-content current" dangerouslySetInnerHTML={{__html: item.description}}></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </section>
  )
})

export default ProductTabs