import React, {memo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ImageUpload from '../../services/general/imageUpload';

const AvatarUploader = ({onChange, value, mini,  dimensions, $error}) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState();

    const removeImage = (e) => {
        e.preventDefault();
        onChange('');
    }

    const uploadImg = async (e) => {
        const currentFile = e.target.files[0];
        setLoading(true);

        const res = await ImageUpload.upload(currentFile);

        if (res.statusCode === 200) {
            toast.success(res.message)
            onChange(res.content.value);
        } else {
            toast.error(res.message)
        }
        setLoading(false);
    }


    return (
        <>
            <div className={mini ? 'fileUpload fileupload-mini' :'fileUpload'}>
                <input id="demo" type="file" name="files" accept="image/jpeg, image/png, text/html, application/zip, text/css, text/js" 
                    onChange={e => uploadImg(e)}
                />
                {value &&
                    <div className='fileUpload-img'>
                        <img src={value} alt=""/>
                        <span onClick={e => removeImage(e)}>
                            <i className='fa fa-close'></i>
                        </span>
                    </div>
                }
                {loading && <div className='isLoading'>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
            </div>
        </>
    );
};

export default AvatarUploader;