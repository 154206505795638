import React from 'react'
import PageTop from '../../components/simple/PageTop'
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';
import img from "../../images/topPage/cart.webp"
import WishListContainer from '../../components/complex/WishListContainer';

const WishList = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.wishlist,
        detail_image: img
    }

  return (
    <div>
        <PageTop item={item}/>
        <WishListContainer/>
    </div>
  )
})

export default WishList