import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Polygon, Popup, TileLayer, Tooltip, useMapEvents } from 'react-leaflet'
import AppServices from '../../services/general/appServices';
import useGeoLocation from '../../hooks/useGeoLocation';

const multiPolygon = [
    [51.176586511280405,71.35477268037421],
    [51.21218081035874,71.48454868135074],
    [51.18435489780707,71.54428684053046],
    [51.13815681350155,71.58651553926092],
    [51.095803735690815,71.55664645967106],
    [51.0694213956337,71.48901187715155],
    [51.068988772238114,71.40695773896793],
    [51.08866902126996,71.34206973847967],
    [51.13966870163141,71.30361759004218],
    [51.176586511280405,71.35477268037421]
    
]

const multiPolygon2 = [
    [51.220589969667046,71.56179630097967],
    [51.18931734468765,71.6129513913117],
    [51.17744973032727,71.62943088349917],
    [51.140964566127685,71.64350711640934],
    [51.114392034514886,71.62256442842106],[51.09083117280135,71.59578525361638],[51.09688465649368,71.55767642793279],[51.13858878661754,71.58617221650702],[51.18413912706237,71.54497348603824],[51.21218081035874,71.48420535859685],[51.239235544075285,71.54325687226873],[51.220589969667046,71.56179630097967]
]

const MapComponent = ({setAddress}) => {
    const mapRef = useRef()
    const [myAddress, setMyAddress] = useState();

    const [isInside, setIsInside] = useState();

    const {location, setLocation} = useGeoLocation();

    const showMyLocation = () => {
        /*if (location.loaded && !location.error) {
            mapRef.current.flyTo(
            [location.coordinates.lat, location.coordinates.lng],
            16, {
                duration: 1
            }
            );
        } else {
            alert(location.error.message);
        }*/
    };
    
    function isMarkerInsidePolygon(marker, poly) {
        var polyPoints = poly;       
        var x = marker.lat, y = marker.lng;
    
        var inside = false;
        for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i][0], yi = polyPoints[i][1];
            var xj = polyPoints[j][1], yj = polyPoints[j][1];
    
            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = true;
        }
        setIsInside(inside)
        return inside;
    };

    const featchAddress = async (lat, lng) => {
        const res = await AppServices.address(lat, lng);
        if(res.address){
            setAddress(`${res.address.road} ${res.address.house_number}`)
        }
    }

    /*useEffect(() => {
        if(location.coordinates){
            featchAddress()
        }
    }, [location])*/

    function LocationMarker() {
        const map = useMapEvents({
          click(e) {
            setLocation(e.latlng);
          },
        })

        if(location.lat){
            featchAddress(location.lat, location.lng)
        }

        return location === null ? null : (
          <Marker position={location}> </Marker>
        )
    }

  return (
    <div className="map__container">
        <MapContainer center={[51.1729850769043,71.42532348632812]} zoom={12} ref={mapRef}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" minZoom={8}
            />
            <div className='location-button' onClick={showMyLocation}>
                <i className='icon-location'></i>
            </div>
            <Polygon pathOptions={{ color: 'green' }} positions={multiPolygon}>
                <Tooltip sticky>Стоимость доставки - 500</Tooltip>
            </Polygon>
            <Polygon pathOptions={{ color: 'purple' }} positions={multiPolygon2}>
                <Tooltip sticky>Стоимость доставки - 700</Tooltip>
            </Polygon>
            {/*location.loaded && !location.error && (
                <Marker
                  position={[
                    location.coordinates.lat,
                    location.coordinates.lng,
                  ]}
                ></Marker>
                )*/}
            <LocationMarker />
        </MapContainer>
        {/*<div onClick={() => isMarkerInsidePolygon(location.coordinates, multiPolygon )}>
            стоимость
            </div>*/}
    </div>
  )
}

export default MapComponent