import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const Banner = ({elem}) => {
    const [bannerBack, setBannerBack] = useState();
    const [leftImage, setLeftImage] = useState();

    useEffect(() => {
        if(elem.elements){
            setBannerBack(elem.elements.find(it => it.type === "banner-back")?.image)
            setLeftImage(elem.elements.find(it => it.type === "left-image")?.image)
        }
    }, [elem.elements])

  return (
    <section id="promo-12" className="promo-section">
        <div className="bg-scroll bg-inner bg-02 division" style={{backgroundImage: `url(https://solution-0003.panama.kz${bannerBack})`}}>
            <div className="container white-color">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-5">
                        <div className="promo-12-txt">
                            <h2>{elem.title}</h2>
                            <div dangerouslySetInnerHTML={{__html: elem.description}}></div>	

                        </div>	
                    </div>            
                    <div className="col-lg-7">	
                        <div className="promo-12-img text-center">
                            <img className="img-fluid" src={leftImage} alt="promo-image"/>
                        </div>
                    </div>
                </div>   
            </div>    
        </div>
    </section>	
  )
}

export default Banner