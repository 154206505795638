import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"
import Utils from '../../services/utils';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import AddToBasket from '../complex/AddToBasket';

const ToCartModal = observer(({show, setShow, slug}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

  return (
    info &&
    <Modal show={show} onHide={handleClose} className='product-view-modal' centered size='lg'>
        <span className='product-modal-close' onClick={handleClose}>
            <i className='icon-close'></i>
        </span>
        <Modal.Body>
            <div className='row'>
                <div className='col-sm-12 col-md-5 img-full-width'>
                    <img src={info.image ? info.image : img} alt="" 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                </div>
                <div className="col-sm-12 col-md-7 product-modal-wrapper">
                    <div className='product-modal'>
                        <div className='category'>{info.parent?.title}</div>
                        <NavLink to={`/${app.lang}/products/${info.slug}`}>
                            <h4>{info.title}</h4>
                        </NavLink>
                        <p>{info.subtitle}</p>
                        <h4 className="price">{Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency}</h4>
                        <div className='product_card_availability'>
                            {app.localizationsItems?.availability}: {
                                    info.availability ?
                                        <span className="text-success">{app.localizationsItems?.in_stock}</span>
                                    :
                                        <span className="text-danger">{app.localizationsItems?.not_available}</span>
                            }
                        </div>
                    </div>
                    <AddToBasket item={info}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ToCartModal