import React from 'react'

const Advantages = ({elem}) => {
  return (
    <section id="contacts-2" className="bg-fixed contacts-section division" style={{backgroundImage: `url(https://solution-0003.panama.kz${elem.steps_image})`}}>				
        <div className="container white-color">
            <div className="row">
                {elem.elements?.map((card, index) =>	
                    <div className="col-md-4" key={index}>
                        <div className="cbox-2 ico-65 clearfix">
                            <span>
                                <img src={card.image} alt="" />
                            </span>
                            <div className="cbox-2-txt">	
                                <h5 className="h5-lg">{card.title}</h5>
                                <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>		
    </section>
  )
}

export default Advantages