import React, { useContext, useRef, useState } from 'react'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { Context } from '../..';
import { useNavigate } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import SearchProductCard from './SearchProductCard';
import { observer } from 'mobx-react-lite';

const SearchIcon = observer(() => {
    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const {app} = useContext(Context);

    const [term, setTerm] = useState('');
    const navigate = useNavigate();

    const {
        items,
        setFilterData
    } = useFetchItems(CatalogServices.products.index)

    const searchOn = () => {
        navigate(`/${app.lang}/catalog?term=${term}`);
        setIsActive(false)
    }

    const dynamicSearch = (e) => {
        setTerm(e.target.value);
        if(e.target.value.length >= 3){
            setFilterData(`?term=${term}`)
        }
        if(e.target.value.length <= 0){
            setFilterData(``)
        }
    }

    const navigateProduct = (card) => {
        navigate(`/${app.lang}/products/${card.slug}`)
        setIsActive(false)
    }
  return (
    <li className='search' ref={dropdownRef}>
        <div className='search-button' onClick={() => setIsActive(!isActive)}>
            <span className={isActive ? "icon-search active" : "icon-search"}></span>
        </div>
        <div className={isActive ? 'search-container search-container-visible' : 'search-container'}>
            <div className='search-input'>
                <input className='form-control' type="text" placeholder={app.localizationsItems?.search_products} 
                    value={term} 
                    onChange={(e) => dynamicSearch(e)}
                />
                <span onClick={searchOn}>
                    <i className='icon-search'></i>
                </span>
            </div>
            <div className='search-wrapper'>
                {items?.items?.map((card, index) => 
                    <SearchProductCard key={index} card={card} onClick={() => navigateProduct(card)}/>
                )}
            </div>
        </div>
    </li>
  )
})

export default SearchIcon