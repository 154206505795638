import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite';
import OrderCard from '../../../components/simple/OrderCard';
import useFetchItems from '../../../hooks/useFetchItems';
import UserServices from '../../../services/general/userServices';

const ActiveOrders = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading
    } = useFetchItems(UserServices.orders.index)

  return (
    <div>
    {items?.items?.length >= 1 ?
        <div>
            {items.items.map((card, index) =>
                <OrderCard card={card} key={index}/>
            )}
        </div>
        :
        <div>
            <h4>{app.localizationsItems?.empty_data}</h4>
        </div>
    }
    </div>
  )
})

export default ActiveOrders