import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form';
import { Context } from '../..';
import UserServices from '../../services/general/userServices';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import EditAddressModal from '../../pages/user/components/EditAddressModal';

const AddressBox = observer(({card, fetchItems}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const {register, formState: {errors}} = useFormContext();

    const [modalOpen, setModalOpen] = useState();

    const deleteAddress = async (e) => {
        e.stopPropagation();
        setLoading(true);

        const res = await UserServices.addresses.delete(card.id);

        if (res.statusCode === 200) {
            fetchItems();
        }
        setLoading(false);
    }   

    const editAddress = (e) => {
        e.stopPropagation();
        setModalOpen(true);

    }

  return (
    <label className={loading ? "address-card isLoading" : "address-card"}>
        <div>
            <div className="form-check">
                <input className="form-check-input" type="radio" value={card.id} name="jack" {...register('address_id', {required: true})}/>
            </div>

            <ul className="delivery-address-detail">
                <li className='flex position-top'>
                    <div></div>
                    <div className='address-icons'>
                        <i className="icon-pen" onClick={e => editAddress(e)}></i>
                        <i className="icon-trash" onClick={e => deleteAddress(e)}></i>
                    </div>
                </li>
                <li>
                    <p className="text-content mb-0">
                        <span className="text-title"></span>{card?.full_address}
                    </p>
                </li>
                <li>
                    <p className="text-content mb-0">
                        <span className="text-title">{app.localizationsItems?.apartment} :</span> {card?.json_address?.apartment || '-'}
                    </p>
                </li>
                <li>
                    <p className="text-content mb-0">
                        <span className="text-title">{app.localizationsItems?.entrance} :</span>{card?.json_address?.entrance || '-'}
                    </p>
                </li>
                <li>
                    <p className="text-content mb-0">
                        <span className="text-title">{app.localizationsItems?.floor} :</span>{card?.json_address?.floor || '-'}
                    </p>
                </li>
                <li>
                    <p className="text-content mb-0">
                        <span className='note'>{app.localizationsItems?.note} : {card?.json_address?.note || '-'}</span>
                    </p>
                </li>
            </ul>
        </div>
        {modalOpen && <EditAddressModal show={modalOpen} setShow={setModalOpen} id={card.id} fetchItems={fetchItems}/>}
    </label>
  )
})

export default AddressBox