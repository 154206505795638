import React, { useContext } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const FavoritesIcon = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();

  return (
    <li className="onhover-dropdown" onClick={() => navigate(`/${app.lang}/wishlist`)}>
        <OverlayTrigger placement="bottom" 
            overlay={
                <Tooltip id="tooltip-view">
                    {app.localizationsItems?.favorites}
                </Tooltip>
            }
        >
            <NavLink to={`/${app.lang}/wishlist`} className="header-icon swap-icon">
                <i className="flaticon-heart"></i>
            </NavLink>
        </OverlayTrigger>
    </li>
  )
})

export default FavoritesIcon