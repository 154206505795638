import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from '../../..'
import { NavLink } from 'react-router-dom';
import useFetchItems from '../../../hooks/useFetchItems';
import UserServices from '../../../services/general/userServices';

const Bonuses = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
    } = useFetchItems(UserServices.profile.balanceHistory)

  return (
    <div className='profile-dashboard'>
        <h4>{app.localizationsItems?.bonuses}</h4>
        <div className='mt-30'>
            {items?.items?.length >= 1 ?
                <div>
                    {items.items.map((card, index) =>
                        <div className='bonus-card' key={index}>
                            <div>
                                <div className='title'>{app.localizationsItems?.order}: №{card.order_id}</div>
                                <div className='date'>{card.created_at}</div>
                                <NavLink to={`/${app.lang}/user/orders/${card.order_id}`}>{app.localizationsItems?.to_order}
                                    <i className='icon-chevron-right'></i>
                                </NavLink>
                            </div>
                            <div className='bonus-state'>
                                + {card.value} ₸
                            </div>
                        </div>
                    )}
                </div>
            :
                <div className='mt-3'><h5>{app.localizationsItems?.empty_data}</h5></div>
            }
        </div>
    </div>
  )
})

export default Bonuses