import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import ArticleCard from '../simple/ArticleCard'
import Pagination from './Pagination'

const ArticlesContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

  return (
	<section id="blog-listing" className="wide-60 blog-page-section division">
		<div className="container">
			<div className="row">
				<div className="col-xl-10 offset-xl-1">
					<div className="row">
                        {items?.items?.map((card, index) =>
                            <ArticleCard card={card} key={index}/>
                        )}
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default ArticlesContainer