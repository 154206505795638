import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const OrderCard = observer(({card}) => {
    const {app} = useContext(Context);
    console.log(card)
  return (
    <NavLink to={`/${app.lang}/user/orders/${card.id}`}>
        <div className='order-card'>
            {card.status && <div className='order-card-state mb-20' style={{backgroundColor: card.status.color}}>{card.status.name}</div>}
            <div className='order-wrapper'>
                <div>
                    <div className='order-text'>пр-кт. Республики, 34а, кВ. 801</div>
                    <div>{app.localizationsItems?.order}: №{card.id}</div>
                    <div>{card.created_at}</div>
                </div>
                <div>
                    <div className='order-price'>{Utils.numberWithSpaces(card.total_price)} {app.localizationsItems?.currency}</div>
                </div>
            </div>
        </div>
    </NavLink>
  )
})

export default OrderCard