import React from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Context } from '../..'
import ProductSlider from '../simple/ProductSlider'
import AddToBasket from './AddToBasket'

const ProductDetailContainer = observer(({item}) => {
    const {app} = useContext(Context);

  return (
    <section id="product-1" className="pt-100 single-product division">
        <div className="container">
            <div className="row">
                    <div className="col-lg-7">
                        {item.gallery?.length >= 1 ? 
                            <ProductSlider slider={item.gallery}/>
                        :   
                            <div className="product-preview">
                                <img className='img-preview' src={item.image} alt="" />
                            </div>
                        }
                    </div>
                    <div className="col-lg-5">
                        <div className="product-description">
                            <div className="project-title">
                                <h2 className="h2-lg">{item.title}</h2>
                                <div className="project-price">
                                    <h4 className="h4-xl">{item.old_price && <span className="old-price">{Utils.numberWithSpaces(item.old_price)} {app.localizationsItems?.currency}</span>}{Utils.numberWithSpaces(item.price)} {app.localizationsItems?.currency}</h4>
                                </div>
                            </div>
                            <div className="product-txt">
                                <p className="p-md grey-color">{item.subtitle}</p>
                                {item.features && <div className="product-info">
                                    {item.features.map((card, index) => 
                                        <p key={index}>{card.title}: <span>{card.value}</span></p>
                                    )}
                                </div>}
                                <AddToBasket item={item}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
  )
})

export default ProductDetailContainer