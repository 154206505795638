import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import ActiveOrders from './ActiveOrders';
import { NavLink } from 'react-router-dom';
import { Context } from '../../..';

const OrderHistory = observer(() => {
    const {app} = useContext(Context);
  return (
    <div className='profile-dashboard'>
        <h4>{app.localizationsItems?.my_orders_history}</h4>
        <div className='order-link-wrapper'>
            <NavLink to={`/${app.lang}/user/orders`} className='order-link'>{app.localizationsItems?.active_orders}</NavLink>
            <div className='order-link active'>{app.localizationsItems?.history}</div>
        </div>
        <ActiveOrders/>
    </div>
  )
})

export default OrderHistory