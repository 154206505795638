import React, { useContext } from 'react'
import { Context } from '../..';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Utils from '../../services/utils';
import ToCartModal from '../modals/ToCartModal';
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import ProductViewModal from '../modals/ProductViewModal';

const WishProductCard = observer(({card, fetchItems}) => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const [show, setShow] = useState();
    const [bigView, setBigView] = useState();

    const deleteItem = async () => {
        setLoading(true);
        const data = {
            uid: user.wishlistUid,
            product_id: card.id
        }
        const res = await UserServices.favorites.toggle(data);

        if(res.statusCode === 200){
            fetchItems()
        }
        setLoading(false);
    }

  return (
    <>
        <div className={loading ? "menu-6-item bg-white isLoading" : "menu-6-item bg-white"}>
            <div className="menu-6-img rel">
                <div className="hover-overlay">
                    <NavLink to={`/${app.lang}/products/${card.slug}`}>
                        <img className="img-fluid" src={card.image} alt={card.title} title={card.title}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            width={259}
                            height={240}
                        />
                    </NavLink>
                    <div className='states-position'>
                        {card.state && <span className="item-code bg-tra-dark" style={{backgroundColor: card.state.subtitle}}>{card.state.name}</span>}
                        {card.bonus && 
                            <div className='bonus-flex'>
                                + {card.bonus.number}
                                <span>{card.bonus.type == 1 ? '%' : ''}</span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                    <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                                </svg>
                            </div>
                        }
                    </div>
                    <div className="menu-img-zoom ico-25" onClick={() => setBigView(true)}>
                        <span className="flaticon-zoom"></span>
                    </div> 
                </div>
                <div className="like-ico ico-25" onClick={deleteItem}>
                    <span className="flaticon-like active"></span>
                </div>
            </div>
            <div className="menu-6-txt rel">
                <NavLink to={`/${app.lang}/products/${card.slug}`}>
                    <h5 className="h5-sm coffee-color">{card.title}</h5>
                </NavLink>
                <p className="grey-color">{card.subtitle}</p>
                <div className="menu-6-price">
                    <h5 className="h5-xs yellow-color">
                        {card.old_price && <span className='old_price'>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
                        {Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}
                    </h5>
                </div>
                <button className="add-to-cart ico-10" onClick={() => setShow(true)}>
                    <span className="flaticon-shopping-bag"></span> {app.localizationsItems?.add_to_cart}
                </button>
            </div>
        </div>
        {show && <ToCartModal show={show} setShow={setShow} slug={card.slug}/>}
        {bigView && <ProductViewModal show={bigView} setShow={setBigView} slug={card.slug}/>}
    </>
  )
})

export default WishProductCard