import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useContext } from 'react';
import { Context } from '../..';
import CartTr from '../simple/CartTr';
import SummaryBox from './SummaryBox';

const CartContainer = observer(() => {
    const {app, user} = useContext(Context);

    const [loading, setLoading] = useState();

  return (
    <section id="cart-1" className="wide-100 cart-page division">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="cart-table mb-70">
                        {user.cartProducts?.length >= 1 ?
                            <table id="myTable">
                                <thead>
                                    <tr>
                                        <th scope="col">{app.localizationsItems?.product_name}</th>
                                        <th scope="col">{app.localizationsItems?.price}</th>
                                        <th scope="col">{app.localizationsItems?.qty}</th>
                                        <th scope="col">{app.localizationsItems?.total}</th>
                                        <th scope="col">{app.localizationsItems?.delete}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.cartProducts.map((card, index) =>
                                        <CartTr card={card} key={index} setLoading={setLoading}/>
                                    )}
                                </tbody>
                            </table>
                            :
                            <div className='text-center'>
                                <h3>{app.localizationsItems?.basket_title}</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>	
            <SummaryBox/>
        </div>
    </section>	
  )
})

export default CartContainer