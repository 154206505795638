import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CatalogServices from '../../services/general/catalogServices';
import OwlCarousel from 'react-owl-carousel';
import ProductCard from '../simple/ProductCard';

const RecentViewProducts = observer(() => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const settings = {
        items: 4,
        nav: false,
        dots: true,
        loop: false,
        margin:20,
        responsive: {
            1200: {items: 4},
            992: {items: 3},
            768: {items: 2},
            480: {items: 2},
            380: {items: 1},
            0: {items: 1}
        }
    }

    const fetchProducts = async () => {
        setLoading(true);
        let str = ''
        let names = JSON.parse(localStorage.getItem("recent_products"));
        names?.forEach(element => {
            str += `ids[]=${element}&` 
        });
        const res = await CatalogServices.products.index(`${str}`);

        if(res.statusCode === 200){
            setItems(res.content);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    localStorage.getItem("recent_products") &&
    <div className='container wide-60'>
        <div className="row">
            <div className="col-lg-10 offset-lg-1">
                <div className="section-title mb-60 text-center">
                    <h2 className="h2-xl red-color">{app.localizationsItems?.recent_view}</h2>
                    <div className="p-xl">
                        <p>{app.localizationsItems?.recent_view_content}</p>
                    </div>
                </div>
            </div>
        </div>
        {items?.items &&
            <OwlCarousel className="owl-carousel recent-slider" {...settings}>
                {items.items.map((card, index) =>
                    <ProductCard card={card} key={index}/>
                )}
            </OwlCarousel>
        }
    </div>
  )
})

export default RecentViewProducts