import React, { useContext } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import Orders from './Orders';
import Dashboard from './Dashboard';
import WishList from './WishList';
import Bonuses from './Bonuses';
import Address from './Address';
import OrderProductDetail from './OrderProductDetail';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import ProfileTabsLink from './ProfileTabsLink';
import OrderHistory from './OrderHistory';

const ProfileTabs = observer(() => {
    const {app} = useContext(Context);

  return (
    <div className="row">
        <ProfileTabsLink/>
        <div className="col-xxl-9 col-lg-8">
                <div className="dashboard-right-sidebar">
                    <Routes>
                        <Route index element={<Dashboard />} />
                        <Route path='dashboard' element={<Dashboard/>}/>
                        <Route path='wishlist' element={<WishList/>}/>
                        <Route path='orders' element={<Orders/>}/>
                        {app.bonuses && <Route path='bonuses' element={<Bonuses/>}/>}
                        <Route path='addresses' element={<Address/>}/>
                        <Route path='orders-history' element={<OrderHistory/>}/>
                        <Route path='orders/:id' element={<OrderProductDetail/>}/>
                    </Routes>
                </div>
        </div>
    </div>
  )
})

export default ProfileTabs