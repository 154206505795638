import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import useFetchItems from '../../../hooks/useFetchItems'
import UserServices from '../../../services/general/userServices'
import { Context } from '../../..'
import WishlistCard from '../../../components/simple/WishlistCard'

const WishList = observer(() => {
    const {app, user} = useContext(Context);

    const {
        items,
        fetchItems,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.favorites.index, user.wishlistUid)

    useEffect(() => {
        if(items){
            if(items.uid && (items.uid !== user.wishlistUid)){
                user.setWishlistUid(items.uid);
            }
        }
    }, [items]) 

  return (
    <div className='profile-dashboard'>
        <h4 className='mb-20'>{app.localizationsItems?.wishlist}</h4>
        <div className="row">
            {items?.items?.map((card, index) =>
                <WishlistCard card={card} key={index} fetchItems={fetchItems}/>
            )}
        </div>
    </div>
  )
})

export default WishList