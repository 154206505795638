import React, { useContext, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.scss';
import Header from './components/complex/Header'
import Footer from './components/complex/Footer';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './navigation/AppRouter';
import { Context } from '.';
import './i18n/i18n'
import AppServices from './services/general/appServices';
import UserServices from './services/general/userServices';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './hooks/scrollToTop';

const App = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const checkAuth = async () => {
        if(user.token){
            const res = await UserServices.profile.index();
            if(res.statusCode === 200){
                
            }else{
                user.removeToken();
            }
        }
    }

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            let settings = res.content;
            app.setSettings(settings);

            let userEnabled = settings.find(it => it.type === "user-enabled");
            userEnabled && app.setUserCabinet(userEnabled.value);

            let registerByPhone = settings.find(it => it.type === "register-by-phone")?.value;
            registerByPhone && app.setRegisterByPhone(registerByPhone);

            let bonusesEnabled = settings.find(it => it.type === "bonuses_enabled")?.value;
            app.setBonuses(bonusesEnabled);

            let color = settings.find(it => it.type === "color-primary")?.value;
            let headerColor = settings.find(it => it.type === "header-color")?.value;
            let footerColor = settings.find(it => it.type === "footer-color")?.value;
            let bgYellow = settings.find(it => it.type === "bg-yellow")?.value;
            let footerFontColor = settings.find(it => it.type === "footer-font-color")?.value;
            let accentColor = settings.find(it => it.type === "accent-color")?.value;
            let buttonCardColor = settings.find(it => it.type === "button-card-color")?.value;
            let yellowColor = settings.find(it => it.type === "yellow-color")?.value;
            let coffeeColor = settings.find(it => it.type === "coffee-color")?.value;
            let profileCard = settings.find(it => it.type === "profile-card")?.value;

            let head = settings.find(it => it.type === "head")?.value;
            let bottom = settings.find(it => it.type === "bottom")?.value;
            let header = settings.find(it => it.type === "header")?.value;
            
            if(head){
                head.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.head.append(divFragment);
                });
            }

            if(header){
                header.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.append(divFragment);
                });
            }

            if(bottom){
                bottom.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.prepend(divFragment);
                });
            }

            color && document.documentElement.style.setProperty('--mainColor', color);
            headerColor && document.documentElement.style.setProperty('--headerColor', headerColor);
            footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
            bgYellow && document.documentElement.style.setProperty('--bgYellow', bgYellow);
            footerFontColor && document.documentElement.style.setProperty('--footerFontColor', footerFontColor);
            accentColor && document.documentElement.style.setProperty('--accentColor', accentColor);
            buttonCardColor && document.documentElement.style.setProperty('--buttonCardColor', buttonCardColor);
            yellowColor && document.documentElement.style.setProperty('--yellowColor', yellowColor);
            coffeeColor && document.documentElement.style.setProperty('--coffeeColor', coffeeColor);
            profileCard && document.documentElement.style.setProperty('--profileCard', profileCard);
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp)
            app.setLocalizationsItems(res2.content.find(it => it.slug === temp).description)
            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
        checkAuth();
    }, [app.lang])

  return (
    <>
        {loading ?
            <div id="loader-wrapper">
                <div id="loader">
                    <div className="cssload-spinner">
                        <div className="cssload-ball cssload-ball-1"></div>
                        <div className="cssload-ball cssload-ball-2"></div>
                        <div className="cssload-ball cssload-ball-3"></div>
                        <div className="cssload-ball cssload-ball-4"></div>
                    </div>
                </div>
            </div>
            :
            <BrowserRouter>
                <ScrollToTop />
                <Header/>
                <AppRouter/>
                <Footer/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        }
    </>
  )
})

export default App