import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext } from 'react'
import { Context } from '../..'
import { useState } from 'react'
import { useEffect } from 'react'
import Utils from '../../services/utils'
import { NavLink } from 'react-router-dom'

const SummaryBox = observer(() => {
    const {app, user} = useContext(Context);

    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [bonuses, setBonuses] = useState();
    const [discount, setDiscount] = useState();

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            let temp2 = 0;
            let temp3 = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                temp2 += Number(user.cartProducts[i]?.old_price ?  user.cartProducts[i]?.old_price : user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                if(user.cartProducts[i].bonus?.type == 1){
                    temp3 += (Math.floor((Number(user.cartProducts[i].price) * Number(user.cartProducts[i].bonus.number))/100) * Number(user.cartProducts[i].count))
                }else if(user.cartProducts[i].bonus){
                    temp3 += (Math.floor(Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count)))
                }
            }
            setTotal(temp);
            setSubTotal(temp2);
            if(temp3 && temp3 > 0){
                setBonuses(temp3);
            }
            if(temp !== temp2){
                setDiscount(Number(temp2)-Number(temp))
            }
        }
    }, [user.cartProducts])

  return (
    <div className="row">
        <div className="col-lg-8">
            <div className="discount-coupon row pt-15">
                <div className="col-md-8 col-lg-7">
                    <form className="discount-form">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder={app.localizationsItems?.cupon} id="discount-code"/>								
                            <span className="input-group-btn">
                                <button type="submit" className="btn btn-salmon tra-salmon-hover">{app.localizationsItems?.apply_cupon}</button>
                            </span>										
                        </div>      
                    </form>	
                </div>
                <div className="col-md-4 col-lg-5 text-right">
                    <a className="btn btn-md btn-salmon tra-salmon-hover">{app.localizationsItems?.cart_update}</a>
                </div>
            </div>
        </div>
        <div className="col-lg-4">
            <div className="cart-checkout bg-lightgrey">
                <h5 className="h5-lg">{app.localizationsItems?.total_cart}</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>{app.localizationsItems?.subtotal}</td>
                            <td> </td>
                            <td className="text-right price">{Utils.numberWithSpaces(subTotal)} {app.localizationsItems?.currency}</td>
                        </tr>
                        {discount && <tr>
                            <td>{app.localizationsItems?.discount}</td>
                            <td> </td>
                            <td className="text-right price">{Utils.numberWithSpaces(discount)} {app.localizationsItems?.currency}</td>
                        </tr>}
                        <tr className="last-tr">
                            <td>{app.localizationsItems?.total}</td>
                            <td> </td>
                            <td className="text-right price">{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</td>
                        </tr>
                    </tbody>
                </table>
                <NavLink to={`/${app.lang}/checkout`} className="btn btn-md btn-salmon tra-salmon-hover">{app.localizationsItems?.checkout}</NavLink>
            </div>
        </div>
    </div>
  )
})

export default SummaryBox