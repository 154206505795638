import { observer } from 'mobx-react-lite';
import React from 'react'
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../..';
import UserServices from '../../../services/general/userServices';
import useFetchInfo from '../../../hooks/useFetchInfo.hook';
import Utils from '../../../services/utils';
import OrderDetailCard from './OrderDetailCard';

const OrderProductDetail = observer(() => {
    const {id} = useParams();

    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.orders.view, id);
    console.log(info)
  return (
    info &&
    <div className='profile-dashboard'>
        <div className='flex align-items-start'>
            <div>
                <h3>{app.localizationsItems?.order} №{info.id}</h3>
                {info.status && <div className='order-card-state mb-20' style={{background: info.status.color}}>{info.status.name}</div>}
            </div>
            <button className='btn btn-red'>{app.localizationsItems?.repeat_order}</button>
        </div>
        <div className="order-card mb-20">
            <h5>{app.localizationsItems?.delivery_payment}</h5>
            <ul className='order-list'>
                <li>{app.localizationsItems?.order_date}: <span>{info.created_at && Utils.generateOrderDate(info.created_at)}</span></li>
                <li>{app.localizationsItems?.address}: <span>{info.address?.full_address}</span></li>
                <li>{app.localizationsItems?.delivery_variant}: <span>{info.delivery?.name}</span></li>
                <li>{app.localizationsItems?.payment_variant}: <span>{info.payment.name}</span></li>
                <li>{app.localizationsItems?.message}: <span>Пожалуйста, убедитесь, что все ингредиенты свежие и качество продуктов на высшем уровне.</span></li>
            </ul>
        </div>
        <div className="order-card mb-20">
            <h5 className='mb-20'>{app.localizationsItems?.order_list}</h5>
            <div className='row'>
                {info.products?.map((card, index) =>
                    <OrderDetailCard card={card} key={index}/>
                )}
            </div>
        </div>
        <div className='order-total-box'>
            <h5 className='flex'>{app.localizationsItems?.total} <span>{Utils.numberWithSpaces(info.total_price)} {app.localizationsItems?.currency}</span></h5>
            <div className='flex'>{app.localizationsItems?.discount} <span>– 500 {app.localizationsItems?.currency}</span></div>
            <div className='flex'>{app.localizationsItems?.bonuses} <span>+300 ₸</span></div>
        </div>
    </div>
  )
})

export default OrderProductDetail