import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DeleteProfileModal = observer(({show, setShow}) => {
    const [visible, setVisible] = useState(false);
    const handleClose = () => setShow(false);

    const navigate = useNavigate();

    const {app, user} = useContext(Context);

    const {handleSubmit} = useForm();

    const CustomSubmit = async () => {
        const res = await UserServices.profile.delete();

        if(res?.statusCode === 200){
            user.setUser({})
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h4 className='mb-4'>{app.localizationsItems?.action_confirmation}</h4>
                <div className='row justify-content-end'>
                    <div className="col-auto">
                        <div className='btn btn-tra-grey' onClick={() => setShow(false)}>{app.localizationsItems?.cancel}</div>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-meat'>{app.localizationsItems?.delete}</button>
                    </div>
                </div>
            </form>
            <div className={visible ? 'form-success form-visible' : 'form-success'}>
                <i className='fa fa-check-circle'></i>
                <h2>{app.localizationsItems?.form_success_title}</h2>
                <p>{app.localizationsItems?.form_success_text}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default DeleteProfileModal