import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import MapComponent from '../complex/MapComponent';
import { useState } from 'react';

const AddAddressMapModal2 = observer(({show, setShow, deliveryAddress, setDeliveryAddress}) => {
    const handleClose = () => setShow(false);

    const [address, setAddress] = useState('');

    const {app} = useContext(Context);

    const {handleSubmit, register, getValues, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if(deliveryAddress){
            setValue('apartment', deliveryAddress.json_address.apartment)
            setValue('entrance', deliveryAddress.json_address.entrance)
            setValue('floor', deliveryAddress.json_address.floor)
        }
    }, [deliveryAddress])

    const CustomSubmit = async (data) => {
        const apartment = getValues('apartment');
        const entrance = getValues('entrance');
        const floor = getValues('floor');
        let str = `${address} `
        str += apartment && `${app.localizationsItems?.apartment}: ${apartment}, `
        str += entrance && `${app.localizationsItems?.entrance}: ${entrance}, `
        str += floor && `${app.localizationsItems?.floor}: ${floor}, `

        const newData = {
            json_address: {
                apartment, entrance, floor
            },
            full_address: str
        }
        setDeliveryAddress(newData);
    };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <MapComponent setAddress={setAddress}/>
            <form className="row justify-content-end" onSubmit={handleSubmit(CustomSubmit)}>
                <div className="col-12">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" value={address} onChange={e=>console.log(e)} placeholder={app.localizationsItems?.address}/>
                        <label>{app.localizationsItems?.address}</label>
                    </div> 
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('apartment')} required placeholder={app.localizationsItems?.apartment}/>
                        <label>{app.localizationsItems?.apartment}</label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('entrance')} required placeholder={app.localizationsItems?.entrance}/>
                        <label>{app.localizationsItems?.entrance}</label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('floor')} required placeholder={app.localizationsItems?.floor}/>
                        <label>{app.localizationsItems?.floor}</label>
                    </div>
                </div>
                <div className="col-auto">
                    <div className='btn btn-red' onClick={CustomSubmit}>{app.localizationsItems?.save}</div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default AddAddressMapModal2