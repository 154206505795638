import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import { Context } from '../..';
import Pagination from './Pagination';
import WishProductCard from '../simple/WishProductCard';

const WishListContainer = observer(() => {
    const {app, user} = useContext(Context);

    const {
        items,
        fetchItems,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.favorites.index, user.wishlistUid)

  return (
    <div className="container wide-70">
        {items?.items?.length >=1 ?
                <div className="row">
                    {items?.items?.map((card, index) =>
                        <div className="col-sm-6 col-lg-3" key={index}>
                                <WishProductCard card={card} fetchItems={fetchItems}/>
                        </div>
                    )}
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
                :
                <div className='text-center'>
                    <h3>{app.localizationsItems?.wishlist_title}</h3>
                </div>
            }
    </div>
  )
})

export default WishListContainer