import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

const PromoBanner = ({elem}) => {
    const [banner, setBanner] = useState();

    useEffect(() => {
        if(elem.elements){
            setBanner(elem.elements[0]);
        }
    }, [elem.elements])

  return (
    banner &&
    <section id="banner-3" className="bg-yellow banner-section division">
        <div className="container">
            <div className="row d-flex align-items-center">
                <div className="col-md-7 col-lg-6">
                    <NavLink to={banner.link} className="banner-3-txt coffee-color2" style={{color: banner.banner_font_color}}>
                        <h4>{banner.subtitle_top}</h4>
                        <h2>{banner.title}</h2>
                        <p className="p-md" dangerouslySetInnerHTML={{__html: banner.description}}></p>
                    </NavLink>
                </div>
                <div className="col-md-5 col-lg-6">
                    <div className="banner-3-img">
                        <img className="img-fluid" src={banner.image} alt="banner-image"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default PromoBanner