import React from 'react'

const ArticleDetailContainer = ({item}) => {
  return (
    <section id="single-post" className="wide-100 single-post-section division">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="single-post-wrapper">
                        <div className="single-post-title">
                            <p>{item.date}</p>
                            <h2 className="h2-xs">{item.title}</h2>
                        </div>
                        <div className='single-post-txt' dangerouslySetInnerHTML={{__html: item.description}}></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ArticleDetailContainer