import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import ProductCard from '../simple/ProductCard'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Context } from '../..'
import { useState } from 'react'
import { useEffect } from 'react'

const Products = observer(({elem}) => {
    const {app, user} = useContext(Context);

    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const fetchProducts = async () => {
        setLoading(true);
        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';
        let str2 = elem.ids ? `category_id=${elem.ids[0]}&` : '';

        const res = await CatalogServices.products.index(`${str}${str2}`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    <section id="menu-6" className="wide-70 menu-section division">
        <div className="container">
            <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                    <div className="section-title mb-60 text-center">	
                        <h2 className="h2-xl red-color">{elem.title}</h2>	
                        <p className="p-xl" dangerouslySetInnerHTML={{__html: elem.description}}></p>
                    </div>	
                </div>
            </div>
            <div className="row">
                {items?.map((card, index) =>
                    <div className="col-sm-6 col-lg-3" key={index}>
                        <ProductCard card={card}/>
                    </div>
                )}
            </div>	 
        </div>	   
    </section>
  )
})

export default Products