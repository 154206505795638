import React from 'react'
import CartContainer from '../../components/complex/CartContainer'
import PageTop from '../../components/simple/PageTop'
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';
import img from "../../images/topPage/cart.webp"

const Cart = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.basket,
        detail_image: img
    }

  return (
    <div>
        <PageTop item={item}/>
        <CartContainer/>
    </div>
  )
})

export default Cart