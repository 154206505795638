import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import Fancybox from '../complex/FancyBox';

const ProductSlider = ({slider}) => {
    const settings = {
        dots: false,
        margin: 10,
        items: 1,
        URLhashListener: true,
        autoplayHoverPause: true,
        startPosition: 'URLHash'
    };

    const settings2 = {
        dots: false,
        margin: 10,
        responsive: {
            1200: {items: 5},
            992: {items: 4},
            768: {items: 3},
            480: {items: 5},
            380: {items: 4},
            0: {items: 3}
        }
    };

  return (
    <div className="product-preview">
        <Fancybox
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
            >
                <OwlCarousel id="product-image" {...settings} >
                    {slider.map((slide, index) =>
                        <div data-hash={`slide${index}`} key={index}>
                            <a href={slide} data-width="700" data-height="700" target="_blank" data-fancybox="gallery">
                                <img src={slide}
                                    height={420}
                                    width={490}
                                />
                            </a>
                        </div>
                    )}
                </OwlCarousel>
        </Fancybox>
        <div>
            <OwlCarousel id="product-carousel" {...settings2}>
                {slider.map((slide, index) =>
                    <a href={`#slide${index}`} key={index}>
                        <div className='product-slider-item'>
                            <img src={slide}
                                height={74}
                                width={74}
                            />
                        </div>
                    </a>
                )}
            </OwlCarousel>
        </div>
    </div>
  )
}

export default ProductSlider