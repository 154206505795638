import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Context } from '../../..';

const ProfileTabsLink = observer(() => {
    const {app} = useContext(Context);
    
    const location = useLocation();

  return (
    <div className="col-xxl-3 col-lg-4">
        <div className="tab-links">
            <div className={(location.pathname === `/${app.lang}/user` || location.pathname === `/${app.lang}/user/dashboard`) ? 'tab-links-item active' : 'tab-links-item'}>
                <Link to={'dashboard'}>
                    <i className="iconly-Home icli"></i>
                    {app.localizationsItems?.dashboard}
                </Link>
            </div>

            <div className={location.pathname === `/${app.lang}/user/orders` ? 'tab-links-item active' : 'tab-links-item'} >
                <Link to={'orders'}>
                    <i className="iconly-Bag-2 icli"></i>
                    {app.localizationsItems?.orders}
                </Link>
            </div>

            <div className={location.pathname === `/${app.lang}/user/wishlist` ? 'tab-links-item active' : 'tab-links-item'} >
                <Link to={'wishlist'}>
                    <i className="iconly-Heart icli"></i>
                    {app.localizationsItems?.wishlist}
                </Link>
            </div>

            {app.bonuses && <div className={location.pathname === `/${app.lang}/user/bonuses` ? 'tab-links-item active' : 'tab-links-item'}>
                <Link to={'bonuses'}>
                    <i className="iconly-Location"></i>
                    {app.localizationsItems?.bonuses}
                </Link>
            </div>}
            
            <div className={location.pathname === `/${app.lang}/user/addresses` ? 'tab-links-item active' : 'tab-links-item'}>
                <Link to={'addresses'}>
                    <i className="iconly-Location"></i>
                    {app.localizationsItems?.all_addresses}
                </Link>
            </div>
        </div>
    </div>
  )
})

export default ProfileTabsLink