import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import ResetPasswordModal from '../modals/ResetPasswordModal';
import UpdatePasswordModal from '../modals/UpdatePasswordModal';
import AuthServices from '../../services/general/authServices';
import { Context } from '../..';

const ForgotPasswordContainer = observer(() => {
    const [email, setEmail] = useState();
    const [code, setCode] = useState();

    const {app} = useContext(Context);

    const [resetPasswordModal, setResetPasswordModal] = useState();
    const [updatePasswordModal, setUpdatePasswordModal] = useState();
    const [loading, setLoading] = useState();

    const {handleSubmit, control, register, formState: {errors}} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.sendCode(data);

        if (res.statusCode === 200) {
            setEmail(data.email);
            setResetPasswordModal(true);
        }else{
            toast.error(res.message)
        }
        setLoading(false);
    }

  return (
    <div id="booking-2" className="wide-70 booking-section division">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div className="form-holder">
                        <div className="text-center">
                            <h3>{app.localizationsItems?.forgot_title}</h3>
                            <p className='p-xl'>{app.localizationsItems?.forgot_content}</p>
                        </div>
                        <form className="row justify-content-end booking-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-lg-12">
                                <input {...register('email')} required type="email" className="form-control email" placeholder={app.localizationsItems?.email}/> 
                            </div>   
                            <div className="col-auto mt-10">  
                                <button type="submit" className="btn btn-md btn-red tra-red-hover submit">{app.localizationsItems?.send_code}</button> 
                            </div>                                     
                        </form>
                    </div>	
                </div>	
            </div>
        </div>
        {resetPasswordModal && <ResetPasswordModal show={resetPasswordModal} setShow={setResetPasswordModal} setUpdatePasswordModal={setUpdatePasswordModal} email={email} setCode={setCode}/>}
        {updatePasswordModal && <UpdatePasswordModal show={updatePasswordModal} setShow={setUpdatePasswordModal} email={email} code={code}/>}
    </div>
  )
})

export default ForgotPasswordContainer