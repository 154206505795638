import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import ForgotPasswordContainer from '../../components/complex/ForgotPasswordContainer'
import img from "../../images/topPage/forgot-password.webp"

const ForgotPassword = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.forgot_password,
        detail_image: img
    }
  return (
    <div>
        <PageTop item={item}/>
        <ForgotPasswordContainer/>
    </div>
  )
})

export default ForgotPassword