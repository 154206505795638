import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import PartnersServices from '../../services/general/partnersServices'
import OwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';

const Partners = () => {
    const {
        items,
    } = useFetchItems(PartnersServices.index)

    const settings = {
        items: 5,
        loop:true,
        autoplay:true,
        navby: 1,
        nav:false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        smartSpeed: 2000,
        responsive:{
            0:{
                items:2
            },
            550:{
                items:3
            },
            767:{
                items:3
            },
            768:{
                items:4
            },
            991:{
                items:5
            },				
            1000:{
                items:6
            }
        }
    }

  return (
    items?.items?.length >= 1 ?
    <div id="brands-1" className="bg-lightgrey brands-section division">
        <div className="container">					
            <div className="row">
                <div className="col text-center">	
                    <OwlCarousel className="owl-carousel brands-carousel" {...settings}>
                        {items?.items?.map((card, index) =>
                            <div className="brand-logo" key={index}>
                                <NavLink to={card.link}>
                                    <img className="img-fluid" src={card.image} alt="brand-logo" /> 
                                </NavLink>
                            </div>
                        )}
                    </OwlCarousel>
                </div>
            </div>     
        </div>	  
    </div>
    :
    <></>
  )
}

export default Partners