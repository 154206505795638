import React, { useContext } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import { NavLink, useNavigate } from 'react-router-dom';
import UserServices from '../../services/general/userServices';
import { useEffect } from 'react';
import CartIconItem from './CartIconItem';
import Utils from '../../services/utils';

const CartIcon = observer(() => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();

    const fetchItems = async () => {
        const res = user.uid ? await UserServices.cart.index(user.uid) : await UserServices.cart.index();

        if(res.statusCode === 200){
            res.content?.products ? user.setCartProducts(res.content.products) : user.setCartProducts([])
            res.content?.total_price ? user.setTotalSum(res.content.total_price) : user.setTotalSum(0)

            if(res.content?.uid && (res.content?.uid !== user.uid)){
                user.setUid(res.content?.uid)
            }
        }
    }

    useEffect(() => {
        fetchItems();
    }, [user.basketChange])

  return (
        <li className="onhover-dropdown basket-ico ico-30">
            <NavLink to={`/${app.lang}/cart`}>
                <span className="ico-holder"><span className="flaticon-shopping-bag"></span> <em className="roundpoint">{user.cartProducts?.length ? user?.cartProducts?.length : 0}</em></span>
            </NavLink>
            <div className="onhover-div">
                    {user.cartProducts?.length >= 1 ?
                        <>
                            <ul className="cart-list">
                                {user.cartProducts?.map((card, index) =>
                                    <CartIconItem key={index} card={card}/>
                                )}
                            </ul>
                            <div className="price-box">
                                <p>{app.localizationsItems?.outcome} :</p>
                                <h5>{Utils.numberWithSpaces(user.totalSum)} {app.localizationsItems?.currency}</h5>
                            </div>
                        </>
                        :
                        <h5 className='mb-20'>{app.localizationsItems?.basket_title}</h5>
                    }
                    <div className="button-group">
                        <NavLink to={`/${app.lang}/cart`} className="btn btn-sm btn-tra-salmon salmon-hover">{app.localizationsItems?.view_cart}</NavLink>
                        <button onClick={() => navigate(`/${app.lang}/checkout`)} disabled={!user?.cartProducts?.length} className="btn btn-sm btn-salmon tra-salmon-hover">{app.localizationsItems?.to_check}</button>
                    </div>
            </div>
        </li>
  )
})

export default CartIcon