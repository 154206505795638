import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import AddAddressMapModal2 from '../modals/AddAddressMapModal2';

const CheckoutAddress = observer(({deliveryAddress, setDeliveryAddress}) => {
    const {app} = useContext(Context);

    const {register, formState: {errors}} = useFormContext();

    const [modalOpen, setModalOpen] = useState(false);

    const handleShow = () => setModalOpen(true);
    
  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-location-dot'/>
        </div>
        <div className='checkout-box'>
            <div className="checkout-title">
                <h4>{app.localizationsItems?.delivery_address}</h4>
                <div className='btn btn-sm btn-red' onClick={handleShow}>{deliveryAddress ? app.localizationsItems?.edit : app.localizationsItems?.add_address}</div>
            </div>
            {deliveryAddress && <div>
                <p>{deliveryAddress.full_address}</p>
            </div>}
        </div>
        {modalOpen && <AddAddressMapModal2 show={modalOpen} setShow={setModalOpen} deliveryAddress={deliveryAddress} setDeliveryAddress={setDeliveryAddress}/>}
    </>
  )
})

export default CheckoutAddress