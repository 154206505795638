import React from 'react'
import { NavLink } from 'react-router-dom'
import Utils from '../../services/utils'
import { useContext } from 'react'
import { Context } from '../..'
import img from "../../images/placeholder.png"

const ProductBestCard2 = ({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="col-sm-6 col-lg-3">
        <NavLink to={`/${app.lang}/products/${card.slug}`}>
            <div id="pb-5-3" className="pbox-5">
                <div className="pbox-5-txt">
                    <h4 className="h4-xs coffee-color">{card.title}</h4>
                    {card.state && <span className="item-code" style={{backgroundColor: card.state.subtitle}}>{card.state.name}</span>}
                    <div className="pbox-5-img text-center mt-25">		
                        <img className="img-fluid" src={card.image ? card.image : img} alt={card.title} title={card.title} 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            width={270}
                            height={200}
                        />
                    </div>
                    <div className="pbox-5-price">
                        <p>{app.localizationsItems?.only}: <span className="red-color"> {Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</span></p>
                    </div>
                </div>
            </div>
        </NavLink>
    </div>
  )
}

export default ProductBestCard2