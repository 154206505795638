import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserContainer from './components/UserContainer';
import img from "../../images/topPage/profile.webp"

const User = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.profile,
        detail_image: img
    }
  return (
    <div>
        <PageTop item={item}/>
        <UserContainer/>
    </div>
  )
})

export default User