import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import img from "../../images/placeholder.png"

const EventCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="col-md-6 col-lg-4">
        <div className="blog-post">
            <div className="blog-post-img">
                <div className="hover-overlay"> 
                    <img className="img-fluid" src={card.image ? card.image : img} alt={card.title} title={card.title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                        height={230}
                        width={416}
                    />
                </div>	
            </div>
            <div className="blog-post-txt">
                <h5 className="h5-lg coffee-color"><NavLink to={`/${app.lang}/articles/${card.slug}`}>{card.title}</NavLink></h5>
                <p className="grey-color">{card.description}</p>
            </div>
        </div>
    </div>
  )
})

export default EventCard