import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const DeleteProductModal = observer(({show, setShow, deleteItem}) => {
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        deleteItem();
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h2 className='mb-4'>{app.localizationsItems?.product_delete_content}</h2>
                <div className='row justify-content-end'>
                    <div className="col-auto">
                        <div className='btn btn-tra-red' onClick={() => setShow(false)}>{app.localizationsItems?.cancel}</div>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-red'>{app.localizationsItems?.delete}</button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default DeleteProductModal