import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop';
import CheckoutContainer from '../../components/complex/CheckoutContainer';
import { Context } from '../..';
import img from "../../images/topPage/checkout.webp"

const Checkout = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.checkout,
        detail_image: img
    }

  return (
    <div>
        <PageTop item={item}/>
        <CheckoutContainer/>
    </div>
  )
})

export default Checkout