import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import MapComponent from '../complex/MapComponent';
import { useState } from 'react';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const AddAddressMapModal = observer(({show, setShow, fetchItems}) => {
    const handleClose = () => setShow(false);

    const [address, setAddress] = useState('');
    const [visible, setVisible] = useState();

    const {app} = useContext(Context);

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        const {apartment, entrance, floor} = data;

        let str = `${address} `
        str += apartment && `${app.localizationsItems?.apartment}: ${apartment}, `
        str += entrance && `${app.localizationsItems?.entrance}: ${entrance}, `
        str += floor && `${app.localizationsItems?.floor}: ${floor}, `

        const newData = {
            json_address: {
                apartment, entrance, floor
            },
            full_address: str
        }

        const res = await UserServices.addresses.store(newData);

        if (res.statusCode === 200) {
            setVisible(true);
            fetchItems();
            setTimeout(() => {
                setVisible(false);
                handleClose();
            }, 3000);
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <MapComponent setAddress={setAddress}/>
            <form className="row justify-content-end" onSubmit={handleSubmit(CustomSubmit)}>
                <div className="col-12">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" value={address} onChange={e=>console.log(e)} placeholder={app.localizationsItems?.address}/>
                        <label>{app.localizationsItems?.address}</label>
                    </div> 
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('apartment')} required placeholder={app.localizationsItems?.apartment}/>
                        <label>{app.localizationsItems?.apartment}</label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('entrance')} required placeholder={app.localizationsItems?.entrance}/>
                        <label>{app.localizationsItems?.entrance}</label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('floor')} required placeholder={app.localizationsItems?.floor}/>
                        <label>{app.localizationsItems?.floor}</label>
                    </div>
                </div>
                <div className="col-auto">
                    <button className='btn btn-red'>{app.localizationsItems?.add}</button>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default AddAddressMapModal