import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Context } from '../..'
import AuthServices from '../../services/general/authServices'

const UserIcon = observer(() => {
    const navigate = useNavigate();

    const {app, user} = useContext(Context);

    const logOut = async () => {
        const res = await AuthServices.logOut();

        if (res.statusCode === 200) {
            user.setUser({})
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
        }
    }

  return (
    <li className='onhover-dropdown'>
        <div>
            <span className="user-button"><i className='icon-user'></i></span>
        </div>
        <div className='onhover-div onhover-dropdown-login'>
            {localStorage.getItem('token') ?
                <ul className="user-box-name">
                    <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/user`}>{app.localizationsItems?.profile}</NavLink>
                    </li>
                    <li className="product-box-contain" onClick={logOut}>
                        <div className='title-a'>{app.localizationsItems?.logout}</div>
                    </li>
                </ul>
                :
                <ul className="user-box-name">
                    <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/login`}>{app.localizationsItems?.login}</NavLink>
                    </li>
        
                    {!app.registerByPhone && <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/registration`}>{app.localizationsItems?.register}</NavLink>
                    </li>}
        
                    {!app.registerByPhone && <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/forgot-password`}>{app.localizationsItems?.forgot_password}</NavLink>
                    </li>}
                </ul>
            }
        </div>
    </li>
  )
})

export default UserIcon