import React from 'react'
import PageTop from '../../components/simple/PageTop'
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../..';
import { useEffect } from 'react';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import ArticleServices from '../../services/general/articleServices';
import ArticleDetailContainer from '../../components/complex/ArticleDetailContainer';

const ArticleDetail = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/articles/${slug}`);
            }
        }
    }, [app.lang])

    const {info, loading} = useFetchInfo(ArticleServices.view, slug);

    const parent = {
        title: app.localizationsItems?.all_news,
        url: `/${app.lang}/articles`
    }
  return (
    <>
        {loading ?
            <div className="isLoading2"></div>
        :
            info &&
            <div>
                <PageTop item={info} parent={parent}/>
                <ArticleDetailContainer item={info}/>
            </div>
        }
    </>
  )
})

export default ArticleDetail