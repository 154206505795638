import React, { useContext, useEffect } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import ProfileTabs from './ProfileTabs'
import useFetchInfo from '../../../hooks/useFetchInfo.hook'
import UserServices from '../../../services/general/userServices'

const UserContainer = observer(() => {
    const {app, user} = useContext(Context);

    const {info, loading, fetchInfo} = useFetchInfo(UserServices.profile.index);

    useEffect(() => {
        if(info){
            user.setUser(info)
        }
    }, [info])

  return (
	<section className='user-profile'>
        <div className='container'>
            {info &&
                <div className="row mb-40">
                    <div className="col-12 mb-30">
                        <h2>{app.localizationsItems?.welcome_profile} {info.name}</h2>
                    </div>
                    <div className="col-6 col-md-6 col-lg-4">
                        <div className='profile-card profile-card1'>
                            <div className='profile-card-title'>{app.localizationsItems?.bonuses}</div>
                            <div className='profile-card-price'>{info.balance}</div>
                            <NavLink className='profile-card-text' to={`/${app.lang}/user/bonuses`}>{app.localizationsItems?.history}</NavLink>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-4">
                        <div className='profile-card profile-card2'>
                            <div className='profile-card-title'>{app.localizationsItems?.my_orders}</div>
                            <div className='profile-card-price'>{info.orders_count}</div>
                            <NavLink className='profile-card-text' to={`/${app.lang}/user/orders`}>{app.localizationsItems?.view_all}</NavLink>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-4">
                        <div className='profile-card profile-card3'>
                            <div className='profile-card-title'>{app.localizationsItems?.wishlist}</div>
                            <div className='profile-card-price'>{info.favourites_count}</div>
                            <NavLink className='profile-card-text' to={`/${app.lang}/user/wishlist`}>{app.localizationsItems?.view_all}</NavLink>
                        </div>
                    </div>
                </div>
            }
            <ProfileTabs/>
        </div>
	</section>
  )
})

export default UserContainer