import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../../..'
import useFetchItems from '../../../hooks/useFetchItems';
import UserServices from '../../../services/general/userServices';
import AddressCard from './AddressCard';
import AddAddressMapModal from '../../../components/modals/AddAddressMapModal';

const Address = observer(() => {
    const {app} = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);

    const handleShow = () => setModalOpen(true);

    const {
        items,
        fetchItems,
        loading
    } = useFetchItems(UserServices.addresses.index)

  return (
    <div className='profile-dashboard'>
        <div className='flex'>
            <h4>{app.localizationsItems?.my_addresses}</h4>
            <div className='red' onClick={handleShow}>+ {app.localizationsItems?.add_address}</div>
        </div>
        {items?.items?.length >= 1 ?
            <div className="row mt-30">
                {items.items.map((card, index) =>
                    <AddressCard card={card} fetchItems={fetchItems} key={index}/>
                )}
            </div>
            :
            <div className='mt-3'><h5>{app.localizationsItems?.empty_data}</h5></div>
        }
        {modalOpen && <AddAddressMapModal show={modalOpen} setShow={setModalOpen} fetchItems={fetchItems}/>}
    </div>
  )
})

export default Address