import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import EventCard from '../simple/EventCard'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const EventsGrid = observer(({elem}) => {
    const {app} = useContext(Context);

    const {
        items,
    } = useFetchItems(ArticleServices.index)

  return (
    <section id="blog-1" className="wide-60 blog-section division">				
        <div className="container">	
            <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                    <div className="section-title mb-60 text-center">	
                        <h2 className="h2-xl red-color">{elem.title}</h2>
                        <div className="p-xl" dangerouslySetInnerHTML={{__html: elem.description}}></div>
                    </div>	
                </div>
            </div>
            <div className="row justify-content-center">
                {items?.items?.slice(0,3).map((card, index) =>
                    <EventCard card={card} key={index}/>
                )}
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <NavLink to={`/${app.lang}/articles`} className='btn btn-red'>{app.localizationsItems?.all_news}</NavLink>
                </div>
            </div>
        </div>		
    </section>
  )
})

export default EventsGrid