import React, { useEffect } from 'react'
import Banner from '../../components/complex/Banner'
import Advantages from '../../components/complex/Advantages'
import Products from '../../components/complex/Products'
import Banners3 from '../../components/complex/Banners3'
import ProductsBestsellers from '../../components/complex/ProductsBestsellers'
import BigBanner from '../../components/complex/BigBanner'
import BestOffers from '../../components/complex/BestOffers'
import PromoBanner from '../../components/complex/PromoBanner'
import EventsGrid from '../../components/complex/EventsGrid'
import Partners from '../../components/complex/Partners'
import Gallery from '../../components/complex/Gallery'
import MainBanner from '../../components/complex/MainBanner'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { Context } from '../..'
import RecentViewProducts from '../../components/complex/RecentViewProducts'

const MainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
        {loading ?
            <div className="isLoading"></div>
            :
            <>
                {items &&
                    items.elements?.map((elem, index) =>
                        <div key={index}>
                            {elem.type === "opening-slider" ?
                                <MainBanner elem={elem}/>
                                :
                            elem.type === "products" ?
                                <Products elem={elem}/>
                                :
                            elem.type === "opening-2" ?
                                <Banner elem={elem}/>
                                :
                            elem.type === "advertising-banner-2" ?
                                Number(elem.banner_type) === 2 ?
                                    <PromoBanner elem={elem}/>
                                    :
                                Number(elem.banner_type) === 3 ?
                                    <Banners3 elem={elem}/>
                                    :
                                    <></>
                                :
                            elem.type === "opening-3" ?
                                <BigBanner elem={elem}/>
                                :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                                :
                            elem.type === "steps" ?
                                <Advantages elem={elem}/>
                                :
                            elem.type === "articles" ?
                                <EventsGrid elem={elem}/>
                                :
                            elem.type === "partners" ?
                                <Partners elem={elem}/>
                                :
                            elem.type === "products-select" ?
                                elem.display_option == "horizontal" ?
                                    <ProductsBestsellers elem={elem}/>
                                    :
                                elem.display_option == "vertical" ?
                                    <BestOffers elem={elem}/>
                                    :
                                    <></>
                                :
                            elem.type === "recent-products" ?
                                <RecentViewProducts item={elem}/>
                                :
                                <></>
                            }
                        </div>
                )}
            </>
        }
    </>
  )
})

export default MainPage