import { observer } from 'mobx-react-lite';
import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import PageTop from '../../components/simple/PageTop';
import { Context } from '../..';
import CatalogContainer from '../../components/complex/CatalogContainer';

const Catalog = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {slug} = useParams();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                slug ? navigate(`/${app.lang}/catalog/${slug}`) : navigate(`/${app.lang}/catalog`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'catalog')

  return (
    <>
    {
        loading ?
            <div className='isLoading2'></div>
            :
            items &&
            <div>
                <PageTop item={items}/>
                <CatalogContainer slug={slug}/>
            </div>
    }
    </>
  )
})

export default Catalog