import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { NavLink } from 'react-router-dom';

const Footer = observer(() => {
    const {app} = useContext(Context);
	const [logo, setLogo] = useState();
    const [socials, setSocials] = useState();

    useEffect(() => {
        if(app.settings){
			setLogo(app.settings.find(it => it.type === "logo-white"));
            setSocials(app.settings.find(it => it.type === "socials"));
        }
    }, [app.settings])

    const {
        items,
    } = useFetchItems(AppServices.menu, 'bottom-menu')

  return (
    <footer id="footer-3" className="footer division">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    {logo && <div className="footer-logo"><img src={logo.value} alt={logo.name} title={logo.name} /></div>}
                    {socials?.value.find(el => el.value) &&
                        <div className="footer-socials-links">
                            <ul className="foo-socials text-center clearfix">
                                {socials.value.map((social, index) =>
                                    social.value &&																
                                    <li key={index}>
                                        <NavLink to={social.value} target='_blank' className="ico-facebook">
                                            <i className={
                                                social.type === "facebook" ? "icon-facebook-f" :
                                                social.type === "twitter" ? "icon-twitter" :
                                                social.type === "vk" ? "icon-vk" :
                                                social.type === "instagram" ? "icon-instagram" :
                                                social.type === "whatsapp" ? "icon-whatsapp" :
                                                social.type === "youtube" ? "icon-youtube" :
                                                social.type === "telegram" ? "icon-telegram-plane" :
                                                ""
                                            }></i>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>                       
                        </div>
                    }
                    <div className="footer-links">
                        <ul className="bottom-footer-list clearfix">
                            {items?.map((card, index) =>
                                <li key={index}><p>
                                    <NavLink to={card.value} target={card.type}>{card.title}</NavLink>
                                </p></li>
                            )}
                        </ul>
                    </div>
                    <div className="footer-copyright">
                        <p>&copy; Panama.kz</p>
                    </div>	
                </div>	
            </div>
        </div>									
    </footer>
  )
})

export default Footer