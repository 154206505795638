import { observer } from 'mobx-react-lite';
import React from 'react'
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import { useEffect } from 'react';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import PageTop from '../../components/simple/PageTop';
import ArticlesContainer from '../../components/complex/ArticlesContainer';

const ArticlePage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/articles`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'articles')

  return (
    <>
        {
            loading ?
                <div className="isLoading2"></div>
            :
            items &&
                <div>
                    <PageTop item={items}/>
                    <ArticlesContainer/>
                </div>
        }
    </>
  )
})

export default ArticlePage