import React from 'react'
import { NavLink } from 'react-router-dom'

const Banners3 = ({elem}) => {
  return (
    <div id="promo-4" className="promo-section division">
        <div className="container">
            <div className="row d-flex align-items-center">
                {elem.elements?.map((card, index) =>
                    <div className="col-md-4" key={index}>
                        <NavLink to={card.link}>
                            <div className="pbox-4">
                                <div className="hover-overlay"> 
                                    <img className="img-fluid" src={card.image} alt="promo-image" />
                                </div> 
                            </div>
                        </NavLink>
                    </div>
                )}
            </div>
        </div>	
    </div>
  )
}

export default Banners3