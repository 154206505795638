import React, { useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

const OrderDetailCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
        <NavLink className='order-card-mini' to={`/${app.lang}/`}>
            <img src={card.image} alt="" />
            <div>
                <h6>{card.title}</h6>
                {card.fields?.map((it, index) =>
                    <div key={index}>{it.name}: {it.value}, </div>
                )}
                <div></div>
                <div className='flex'>
                    <h6>{card.count} {app.localizationsItems?.count_pc}</h6>
                    <h6>{card.price} {app.localizationsItems?.currency}</h6>
                </div>
            </div>
        </NavLink>
    </div>
  )
})

export default OrderDetailCard