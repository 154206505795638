import React, { useContext, useState } from 'react'
import AuthServices from '../../services/general/authServices';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PhoneCheckModal = observer(({show, setShow, phone}) => {
    const [loading, setLoading] = useState();

    const {app, user} = useContext(Context);

    const {handleSubmit, register} = useForm();

    const handleClose = () => setShow(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true)
        const newData = {
            phone:phone,
            ...data
        }

        const res = await AuthServices.authWithPhone(newData);

        if (res.statusCode === 200) {
            setShow(false);
            toast.success(res.message);
            user.setToken(res.content.token);
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`)
        }else{
            toast.error(res.message);
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
						<h3>{app.localizationsItems?.regis_title}</h3>
                        <p>{app.localizationsItems?.phone_check_send}</p>
					</div>
                    <div className="form-floating theme-form-floating log-in-form">
						<input type="text" {...register('code')} required className="form-control" placeholder={app.localizationsItems?.enter_code}/>
						<label>{app.localizationsItems?.enter_code}</label>
					</div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <button type="submit" className="btn btn-sm btn-tra-grey">{app.localizationsItems?.cancel}</button>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-sm btn-red">{app.localizationsItems?.send}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default PhoneCheckModal