import React from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Context } from '../..'
import { useState } from 'react'
import UserServices from '../../services/general/userServices'
import { toast } from 'react-toastify'
import DeleteProductModal from '../modals/DeleteProductModal'

const CartTr = observer(({card, setLoading}) => {
    const {app, user} = useContext(Context);

    const [count, setCount] = useState(card.count);
    const [deleteModal, setDeleteModal] = useState();

    const changeCount = async (e) => {
        const data = {
            uid: user.uid,
            product_id: card.id,
            count: e.target.value
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            setCount(e.target.value);
            user.setBasketChange(Math.random().toString(16))
        }else{
            toast.error(res.message)
        }
    }

    const deleteItem = async () => {
        setLoading(true);

        const data = {
            uid: user.uid,
            product_id: card.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            setLoading(false);
            setDeleteModal(false)
        }else{
            toast.error(res.message)
        }
    }
    
  return (
    <tr>
        <td data-label={app.localizationsItems?.product_name} className="product-name">
            <div className="cart-product-img"><img src={card.image} alt="cart-preview"/></div>
            <div className="cart-product-desc">
                <h5 className="h5-sm">{card.title}</h5>
                <p className="p-sm">{card.subtitle}</p>
            </div>
        </td>
        <td data-label={app.localizationsItems?.price} className="product-price price"><h5 className="h5-md">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h5></td>
        <td data-label={app.localizationsItems?.qty} className="product-qty">
            <input className="qty" type="number" min="1" max="20" value={count} onChange={e => changeCount(e)}/>
        </td>
        <td data-label={app.localizationsItems?.total} className="product-price-total price"><h5 className="h5-md">{Utils.numberWithSpaces(Number(card.price) * Number(card.count))} {app.localizationsItems?.currency}</h5></td>
        <td data-label={app.localizationsItems?.delete} className="td-trash" ><i className="icon-trash" onClick={() => setDeleteModal(true)}></i></td>
        {deleteModal && <DeleteProductModal show={deleteModal} setShow={setDeleteModal} deleteItem={deleteItem}/>}
    </tr>
  )
})

export default CartTr