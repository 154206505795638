import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../../..'
import img from "../../../images/user/user.jpg"
import DeleteProfileModal from '../../../components/modals/DeleteProfileModal'
import EditProfileModal from './EditProfileModal'

const Dashboard = observer(() => {
    const {app, user} = useContext(Context);
    const [deleteModal, setDeleteModal] = useState();
    const [editModal, setEditModal] = useState();

  return (
    <div className='profile-dashboard'>
        <div className="flex">
            <h4>{app.localizationsItems?.profile}</h4>
            <span className='span' onClick={()=>setEditModal(true)}>
                {app.localizationsItems?.edit}
                <i className='icon-pen'></i>
            </span>
        </div>
        {user.user &&
            <div>
                <div className='profile-dashboard-wrapper mt-30'>
                    <img src={img} alt="" />
                    <div className='profile-dashboard-text'>
                        <div>
                            <span>{app.localizationsItems?.name}</span>
                            <p>{user.user.name}</p>
                        </div>
                        <div>
                            <span>{app.localizationsItems?.email}</span>
                            <p>{user.user.email}</p>
                        </div>
                        <div>
                            <span>{app.localizationsItems?.phone}</span>
                            <p>{user.user.phone}</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex mt-20 profile-text'>
                    <div className='mr-40'>
                        <span>{app.localizationsItems?.birthday}</span>
                        <p>{user.user.name}</p>
                    </div>
                    <div>
                        <span>{app.localizationsItems?.gender}</span>
                        <p>{user.user.name}</p>
                    </div>
                </div>
            </div>
        }
        <div className='delete-container'>
            <span onClick={() => setDeleteModal(true)}>
                {app.localizationsItems?.delete_profile}
                <i className='icon-trash'></i>
            </span>
        </div>
        {deleteModal && <DeleteProfileModal show={deleteModal} setShow={setDeleteModal}/>}
        {editModal && <EditProfileModal show={editModal} setShow={setEditModal}/>}
    </div>
  )
})

export default Dashboard