import React, { useContext, useEffect, useState } from 'react'
import UserServices from '../../../services/general/userServices';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../../hooks/useFetchInfo.hook';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import MapComponent from '../../../components/complex/MapComponent';

const EditAddressModal = observer(({show, setShow, id, fetchItems}) => {
    const [visible, setVisible] = useState(false);

    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.addresses.view, id);

    const [address, setAddress] = useState();

    const handleClose = () => setShow(false);

    const {handleSubmit, register, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if (info) {
            const {json_address} = info;
            for (const [key, value] of Object.entries(json_address)) {
                json_address[key] !== null && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const CustomSubmit = async (data) => {
        const newData = {
            json_address: {
                ...data
            },
            full_address: `${data.city}, ${app.localizationsItems?.street} ${data.street} ${data.house}, ${app.localizationsItems?.apartment} ${data.apartment}, ${app.localizationsItems?.entrance} ${data.entrance}, ${app.localizationsItems?.floor} ${data.floor}, ${app.localizationsItems?.note} ${data.note}`
        }

        const res = await UserServices.addresses.update(id, newData);

        if (res.statusCode === 200) {
            setVisible(true);
            fetchItems();
            setTimeout(() => {
                setVisible(false);
            }, 3000);
        }
    };
  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <MapComponent setAddress={setAddress}/>
            <form className="row justify-content-end" onSubmit={handleSubmit(CustomSubmit)}>
                <div className="col-12">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" value={address} onChange={e=>console.log(e)} placeholder={app.localizationsItems?.address}/>
                        <label>{app.localizationsItems?.address}</label>
                    </div> 
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('apartment')} required placeholder={app.localizationsItems?.apartment}/>
                        <label>{app.localizationsItems?.apartment}</label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('entrance')} required placeholder={app.localizationsItems?.entrance}/>
                        <label>{app.localizationsItems?.entrance}</label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('floor')} required placeholder={app.localizationsItems?.floor}/>
                        <label>{app.localizationsItems?.floor}</label>
                    </div>
                </div>
                <div className="col-auto">
                    <div className='btn btn-red' onClick={CustomSubmit}>{app.localizationsItems?.save}</div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default EditAddressModal