import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext } from 'react'
import { Context } from '../..'
import Utils from '../../services/utils'
import { NavLink } from 'react-router-dom'
import ToCartModal from '../modals/ToCartModal'
import { useState } from 'react'
import UserServices from '../../services/general/userServices'
import ProductViewModal from '../modals/ProductViewModal'

const WishlistCard = observer(({card, fetchItems}) => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const [show, setShow] = useState();

    const deleteItem = async () => {
        setLoading(true);
        const data = {
            uid: user.wishlistUid,
            product_id: card.id
        }
        const res = await UserServices.favorites.toggle(data);

        if(res.statusCode === 200){
            fetchItems()
        }
        setLoading(false);
    }

  return (
    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-20">
        <div className="menu-6-item bg-white">
            <div className="menu-6-img rel">
                <div className="hover-overlay">
                    <NavLink to={`/${app.lang}/products/${card.slug}`}>
                        <img className="img-fluid" src={card.image} alt="menu-image" />
                    </NavLink>
                </div>
                <div className="like-ico ico-25" onClick={deleteItem}>
                    <span className="flaticon-like active"></span>
                </div>
            </div>
            <div className="menu-6-txt rel">
                <NavLink to={`/${app.lang}/products/${card.slug}`}>
                    <h5 className="h5-sm coffee-color">{card.title}</h5>
                </NavLink>
                <div className="menu-6-price bg-coffee">
                    <h5 className="h5-xs yellow-color">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h5>
                </div>
                <button className="add-to-cart bg-yellow ico-10" onClick={() => setShow(true)}>
                    <span className="flaticon-shopping-bag"></span> {app.localizationsItems?.add_to_cart}
                </button>
            </div>
        </div>
        {show && <ToCartModal show={show} setShow={setShow} slug={card.slug}/>}
    </div>
  )
})

export default WishlistCard